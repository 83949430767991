<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation background cover-text ps-0" style="margin-top: 50px">
          <h1>非凸升级服务组件</h1>
          <p>基于智能时代管理人对智能交易平台的需求，聚焦中小投资者，前瞻性自研开发的一站式AI金融终端</p>
        </div>
      </div>
    </div>
    <div class="content p-auto" style="margin-top:50px">
      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>绩效分析系统</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/兼容性.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">评价</h3>
                    <p class="text-dark text-left">
                      针对算法全盘、厂商、客户的数据表现定期评价客户算法使用情况，展示绩效与主要评价标准关系，根据客户的算法特征，推荐合适算法
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/高性能.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">对比</h3>
                    <p class="text-dark text-left">
                      对比主要厂商的主要交易特性，定期就算法厂商的数据表现进行对比，明确算法厂商之间的相对优劣
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>数据回测系统</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/算法管理.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">模拟历史交易环境</h3>
                    <p class="text-dark text-left">
                      使用Level2行情快照叠加逐笔委托/成交数据，精确模拟交易所撮合逻辑
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/订单管理.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">支持拆单及底仓增强算法回测</h3>
                    <p class="text-dark text-left">
                      支持客户针对拆单及TO算法结合自身策略底仓进行回测，了解算法可能表现情况，并对自身策略做一些调整以更适应算法绩效表现
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/风险控制.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">支持自有策略回测</h3>
                    <p class="text-dark text-left">
                      支持客户对自有策略进行回测，需要进行一定系统开发改造
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>模拟仿真系统</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/稳定性.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">原始数据</h3>
                    <p class="text-dark text-left">
                      使用真实Level2行情，模拟盘口实时仿真
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/易用性.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">高精度撮合</h3>
                    <p class="text-dark text-left">
                      实时撮合结果可视化，可供客户进行模拟盘多策略运行对比测试
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>运维监控系统</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/风险控制.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">实时监控</h3>
                    <p class="text-dark text-left">
                      完善的监控中心界面，对全账户任务执行情况统一监控，实时把握交易进度
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/实时监控与风险控制.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">异常报警</h3>
                    <p class="text-dark text-left">
                      利用完善的风控体系，和周密的报警模块辅助，及时发现并处理突发问题，可进行日志查询
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>智能路由系统</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/基础评价.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">算法评价</h3>
                    <p class="text-dark text-left">
                      根据回测、模拟盘、实盘数据对不同算法特性进行评价、数据分析和展示，识别用户持仓，利用评价结果自动匹配合适算法，提高绩效表现
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/交易总线/算法管理.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">精准匹配</h3>
                    <p class="text-dark text-left">
                      真正实现算法的算法，为客户精准匹配合适算法，进而节省算法筛选的成本，提升智能交易的效能
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      </div>
  </div>
</template>



<script>
export default {
  name: "Bus",
};
</script>

<style scoped>
.jumbotron-wrapper.image {
  position: relative;
  top: 50px;
  background-image: url("~@/assets/img/products/智能交易总线.png");
  background-position-y: 10px;
}
.cover-text {
  color: unset;
  text-shadow: none;
}
</style>
